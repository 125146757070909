import React, { useEffect, useState } from "react";

interface Props {
	label: string;
	name: string;
	options: { value: string; label: string }[];
	initialValues?: string[];
	type?: "radio" | "checkbox";
	onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function RadioCheckboxInput({
	label,
	name,
	options,
	initialValues = [],
	onChange,
	type = "radio",
}: Props) {
	const [selectedOptions, setSelectedOptions] =
		useState<string[]>(initialValues);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let inputElement;
		if (e.target.tagName === "INPUT") {
			inputElement = e.target as HTMLInputElement;
		} else {
			inputElement = e.target.querySelector("input") as HTMLInputElement;
		}
		const { value, checked } = inputElement;

		if (type === "radio") {
			setSelectedOptions([value]);
		} else {
			setSelectedOptions((prev) =>
				checked ? [...prev, value] : prev.filter((option) => option !== value),
			);
		}
	};

	useEffect(() => {
		onChange({
			target: {
				name: name,
				value: selectedOptions.join(","),
			},
		} as React.ChangeEvent<HTMLInputElement>);
	}, [selectedOptions]);

	return (
		<div className="radio-input__wrapper">
			{label && (
				<label className="basic-input__label" htmlFor={name}>
					{label}
				</label>
			)}
			<div className="radio-input__container">
				{options &&
					options.map((option) => (
						<label
							key={option.value}
							className="radio-input__field-container"
							htmlFor={option.value}
						>
							<input
								className="custom-input-field"
								type={type}
								id={option.value}
								name={name}
								value={option.value}
								onChange={(e) => handleChange(e)}
								checked={selectedOptions.includes(option.value)}
							/>
							<div className="radio-input__checkbox"></div>
							{option.label}
						</label>
					))}
			</div>
		</div>
	);
}
