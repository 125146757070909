import React, { useEffect, useState } from "react";
import InputField from "../../shared/inputs/InputField";
import SelectInput from "../../shared/inputs/SelectInput";
import RadioCheckboxInput from "../../shared/inputs/RadioCheckboxInput";
import Pagination from "../../shared/Pagination";
import API from "../api";
import debounce from "../helpers";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../shared/loading-spinner/loading-spinner";

export function Offers({ domain }: { domain: string }) {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [q, setQ] = useState("");
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [order, setOrder] = useState("datum-najnovije");
	const [filters, setFilters] = useState<any>([]);
	const [status, setStatus] = useState("all");
	const [listings, setListings] = useState<any>([]);
	const navigate = useNavigate();
	const [selectedListings, setSelectedListings] = useState<number[]>([]);

	const handleDelete = (ids: number[]) => {
		if (!ids.length) {
			return;
		}
		const msgPlural = ids.length > 1 ? "ove oglase" : "ovaj oglas";
		if (confirm(`Jeste li sigurni da želite izbrisati ${msgPlural}?`) == true) {
			setIsLoading(true);
			API.deleteListings(domain, ids).then((result: any) => {
				updateListings();
			});
		}
	};

	const updateListings = async () => {
		setIsLoading(true);
		try {
			const result = await API.getListings(
				domain,
				q,
				page,
				pageSize,
				order,
				status,
			);
			setListings(result);
			setSelectedListings([]);
		} catch (error) {
			console.error("Error updating listings:", error);
		} finally {
			setIsLoading(false);
		}
	};

	// Get listings on init
	useEffect(() => {
		updateListings();
	}, []);

	// Get listings on search
	useEffect(() => {
		const { debouncedFunction, cancelDebounce } = debounce(async () => {
			await updateListings();
		}, 600);

		debouncedFunction();

		return () => {
			cancelDebounce();
		};
	}, [q]);

	// Get listings on page size change and order change
	useEffect(() => {
		// this weird logic is here to prevent double renders on page change
		if (page === 1) {
			updateListings();
		} else {
			setPage(1);
		}
	}, [pageSize, order, status]);

	// Get listings on page change
	useEffect(() => {
		updateListings();
	}, [page]);

	useEffect(() => {
		const itemCounts = listings.itemCounts
			? listings.itemCounts
			: {
					all: 0,
					draft: 0,
					pending: 0,
					publish: 0,
			  };

		setFilters([
			{ label: "Sve", name: "all", count: itemCounts.all ? itemCounts.all : 0 },
			{
				label: "Aktivno",
				name: "publish",
				count: itemCounts.publish ? itemCounts.publish : 0,
			},
			{
				label: "Čeka odobrenje",
				name: "pending",
				count: itemCounts.pending ? itemCounts.pending : 0,
			},
			{
				label: "Skica",
				name: "draft",
				count: itemCounts.draft ? itemCounts.draft : 0,
			},
		]);
	}, [listings]);

	return (
		<div className="my-offers">
			<div className="my-offers__title">
				<h2>Moje ponude</h2>
				<p>Organiziraj svoje ponude na jednom mjestu</p>
			</div>

			<div className="my-offers__search">
				<InputField
					type={"text"}
					name={"search"}
					value={q}
					placeholder={"Pretraži"}
					onChange={(e) => {
						setQ(e.target.value);
					}}
				></InputField>

				<div className="size">
					<h5>Broj prikaza</h5>
					<SelectInput
						label={""}
						name={"size"}
						options={[
							{ value: "10", label: "10" },
							{ value: "25", label: "25" },
							{ value: "50", label: "50" },
						]}
						initialValue={"25"}
						placeholder={""}
						onChange={(e) => setPageSize(e.target.value)}
					></SelectInput>
				</div>

				<div className="sort">
					<h5>Poredaj po</h5>
					<SelectInput
						label={""}
						name={"sort"}
						options={[
							{ value: "datum-najnovije", label: "Najnovije" },
							{ value: "datum-najstarije", label: "Najstarije" },
							{ value: "cijena-visa", label: "Najviša cijena" },
							{ value: "cijena-niza", label: "Najniža cijena" },
						]}
						initialValue={"datum-najnovije"}
						placeholder={""}
						onChange={(e) => setOrder(e.target.value)}
					></SelectInput>
				</div>
			</div>

			<div className="my-offers__filters">
				{" "}
				{filters.map((filter) => {
					return (
						<button
							className={filter.name === status ? "selected" : ""}
							onClick={() => setStatus(filter.name)}
						>
							<p>{filter.label}</p>
							<div>{filter.count}</div>
						</button>
					);
				})}{" "}
			</div>

			<div
				className="my-offers__select-all"
				style={
					!isLoading && listings.listings && listings.listings.length
						? {}
						: { visibility: "hidden" }
				}
			>
				<div style={{ visibility: "hidden" }}>
					<RadioCheckboxInput
						label=""
						name="select-all"
						options={[{ value: "select-all", label: "Označi sve" }]}
						onChange={(e) => {}}
						type="checkbox"
					></RadioCheckboxInput>
				</div>
				<button
					disabled={!selectedListings.length}
					className={`delete${
						!selectedListings.length ? " delete--disabled" : ""
					}`}
					onClick={() => handleDelete(selectedListings)}
				>
					Obriši
				</button>
			</div>

			<div className="my-offers__listings">
				{!isLoading &&
					listings.listings &&
					(listings.listings.length ? (
						listings.listings.map((filter: any) => {
							return (
								<div className="listing" id={"listing_" + filter.id}>
									<RadioCheckboxInput
										label=""
										name={"listing_" + filter.id}
										options={[{ value: String(filter.id), label: "" }]}
										onChange={(e) => {
											const isChecked = !!e.target.value;
											setSelectedListings((prev) => {
												if (isChecked) {
													return [...prev, filter.id];
												} else {
													return prev.filter((id) => id !== filter.id);
												}
											});
											const listingBody = document
												.getElementById(e.target.name)
												?.getElementsByClassName("body")[0];
											if (isChecked) {
												listingBody?.classList.add("selected");
											} else {
												listingBody?.classList.remove("selected");
											}
										}}
										type="checkbox"
									></RadioCheckboxInput>

									<div className="body">
										{filter.status !== "active" && (
											<div className={"banner " + filter.status}>
												<div className="icon"></div>
												<div className="status">
													{filter.status === "pending"
														? "Čeka se odobrenje"
														: filter.status === "draft"
														? "Skica"
														: ""}
												</div>
											</div>
										)}
										<img className="image" src={filter.thumbnailUrl} />
										<div className="description">
											<div className="text">
												<div className="label" dangerouslySetInnerHTML={{__html:filter.title}}></div>
												<div className="price">
													{filter.price_upon_request
														? "Na upit"
														: Intl.NumberFormat("hr-HR").format(filter.price) +
														  " €"}
												</div>
											</div>
											<div className="footer">
												<button
													className="edit"
													onClick={(event) => {
														event.preventDefault();

														navigate("/ponude/" + filter.id);
													}}
												>
													<div></div>
													<p>Uredi</p>
												</button>
												<button
													className="delete"
													onClick={() => handleDelete([filter.id])}
												>
													<div></div>
													<p>Obriši</p>
												</button>
											</div>
										</div>
									</div>
								</div>
							);
						})
					) : (
						<div className="my-offers__no-offers">Nema oglasa</div>
					))}
				{isLoading && <LoadingSpinner />}
			</div>

			<div className="my-offers__paginator">
				{listings.totalPages > 1 && (
					<Pagination
						page={page}
						totalPages={listings.totalPages}
						useQueryParams={false}
						queryParamsCallback={(page: number) => {
							setPage(page);
						}}
					/>
				)}
			</div>
		</div>
	);
}
