import React, {useEffect, useRef, useState} from "react";
import API from "../api";
import { Bounce, toast } from "react-toastify";
import LoadingSpinner from "../../shared/loading-spinner/loading-spinner";

export function ProfileManagement({ domain, values } : { domain: string; values: any; })
{
	const [isPrivateUser, setIsPrivateUser] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const switchRef = useRef<HTMLInputElement>(null);
	const deleteDialog = useRef<HTMLDialogElement>(null);
	const switchDialog = useRef<HTMLDialogElement>(null);

	useEffect(() => {
		/* switchRef.current!.checked = !values.private_user;
		setIsPrivateUser(values.private_user); */
	}, []);

	function changeUserType() {
		setIsLoading(true);

		API.switchUserType(domain).then(() => {
			setIsLoading(false);
			setIsPrivateUser(isPrivateUser => !isPrivateUser);
			switchRef.current!.checked = isPrivateUser;
			switchDialog.current?.close();

			toast.success('Profil korisnika uspješno promijenjen.', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});

		}, () => {
			setIsLoading(false);
			switchDialog.current?.close();

			toast.error('Nešto je pošlo po krivu. Profil korisnika nije promijenjen.', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});
		})
	}

	function deleteUser() {
		setIsLoading(true);

		API.deleteProfile(domain).then(() => {
			setIsLoading(false);

			toast.success('Korisnički račun uspješno obrisan. Preusmjeravanje na naslovnicu.', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});

			window.location.href = "/";

		}, () => {
			setIsLoading(false);
			deleteDialog.current?.close();

			toast.error('Nešto je pošlo po krivu. Korisnički račun nije obrisan.', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});
		});
	}

	return <section className="profile__management">
{/* 		<div className="profile__management__item">
			<div className="profile__management__item__description first">
				<h6 className="title">
					Zamjena profila korisnika
					<label className="switch">
						<input type="checkbox" name="isPrivateUser"
							ref={switchRef}
							onClick={(event) => {
								event.preventDefault();
								switchDialog.current?.showModal();
							}}
						/>
						<span className="slider"></span>
					</label>
				</h6>
				<p className="hint">
					TRENUTNI TIP KORISNIKA: { isPrivateUser ? "PRIVATNI" : "POSLOVNI"}
				</p>
				<p className="statement">
					Ukoliko želite prabaciti račun s privatnog na poslovnog korisnika morat će te upisivati sve podatke ispočetka.
				</p>
			</div>
		</div> */}

		<div className="profile__management__item">
			<div className="profile__management__item__description">
				<h6 className="title">
					Obriši korisnički račun
				</h6>
				<p className="statement">
					Brisanjem korisničkog računa brišu se svi vaši podaci i oglasi na Shopaj24.
				</p>
			</div>

			<button type="button" onClick={() => deleteDialog.current?.showModal()}>
				Obriši račun
			</button>
		</div>

		<dialog ref={deleteDialog}>
		{ isLoading
				? <LoadingSpinner></LoadingSpinner>
				: <>
				<h1>Obriši korisnički račun</h1>
				<div className="dialog__message">
					Brisanjem korisničkog računa brišu se svi vaši podaci i oglasi na Shopaj24.
				</div>

				<div className="dialog__buttons">
					<button className="delete" onClick={() => deleteUser()}>Obriši račun</button>
					<button onClick={() => deleteDialog.current?.close()}>
						Odustani
					</button>
				</div>
			</>
		}
		</dialog>

{/* 		<dialog ref={switchDialog}>
				{ isLoading
				? <LoadingSpinner></LoadingSpinner>
				: <>
				<h1>Zamjena profila korisnika</h1>
				<div className="dialog__message">
					Ukoliko želite prebaciti račun s privatnog na poslovnog korisnika morat će te upisivati sve podatke ispočetka.
				</div>

				<div className="dialog__buttons">
					<button className="delete" onClick={() => changeUserType()}>Zamijeni račun</button>
					<button onClick={() => switchDialog.current?.close()}>
						Odustani
					</button>
				</div>
				</>
			}


		</dialog> */}
	</section>
}
