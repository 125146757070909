export default function debounce(func: Function, delay: number) {
	let timeoutId: ReturnType<typeof setTimeout>;

	function debouncedFunction(...args: any[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			// @ts-ignore
			func.apply(this, args);
		}, delay);
	}

	function cancelDebounce() {
		clearTimeout(timeoutId);
	}

	return {
		debouncedFunction,
		cancelDebounce,
	};
}
