import React from "react";
import { useNavigate } from "react-router-dom";

export function Settings() {
	const navigate = useNavigate();

	function setWindowScrollingState() {
		document.body.style.width = "auto";
		document.body.style.height = "auto";
		document.getElementsByTagName("html")[0].style.overflow = "auto";
	}

	return (
		<div className="settings--home">
			<div className="title">
				<h1>Postavke</h1>
				<p>Ovdje možeš upravljati svojim osobnim podatcima.</p>
			</div>
			<div className="links">
				<a href="" className="link"
				onClick={(event) => {
						event.preventDefault();
						const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						settingsMenu.classList.remove("settings-visible");
						setWindowScrollingState();
						navigate("/generalne-postavke");
					}}
				>
					<span className="link--title">
						<img src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-acc.svg" />
						<p>Korisnički račun</p>
					</span>
					<img
						src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-arrow.svg"
						className="link--arrow"
					/>
				</a>
				<a href="" className="link"
				onClick={(event) => {
						event.preventDefault();
						const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						settingsMenu.classList.remove("settings-visible");
						setWindowScrollingState();
						navigate("/profilna-cover");
					}}
				>
					<span className="link--title">
						<img src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-photo.svg" />
						<p>Profilna i cover fotografija</p>
					</span>
					<img
						src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-arrow.svg"
						className="link--arrow"
					/>
				</a>
				<a href="" className="link"
				onClick={(event) => {
						event.preventDefault();
						const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						settingsMenu.classList.remove("settings-visible");
						setWindowScrollingState();
						navigate("/drustvene");
					}}
				>
					<span className="link--title">
						<img src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-links.svg" />
						<p>Linkovi na društvene mreže</p>
					</span>
					<img
						src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-arrow.svg"
						className="link--arrow"
					/>
				</a>
				<a href="" className="link"
				onClick={(event) => {
						event.preventDefault();
						const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						settingsMenu.classList.remove("settings-visible");
						setWindowScrollingState();
						navigate("/promijeni-lozinku");
					}}
				>
					<span className="link--title">
						<img src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-pass.svg" />
						<p>Promijeni lozinku</p>
					</span>
					<img
						src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-arrow.svg"
						className="link--arrow"
					/>
				</a>
				<a href="" className="link"
				onClick={(event) => {
						event.preventDefault();
						const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						settingsMenu.classList.remove("settings-visible");
						setWindowScrollingState();
						navigate("/promijeni-email");
					}}
				>
					<span className="link--title">
						<img src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-email.svg" />
						<p>Promijeni e-mail</p>
					</span>
					<img
						src="/wp-content/plugins/shopaj-blocks/src/assets/account-set-arrow.svg"
						className="link--arrow"
					/>
				</a>
			</div>
		</div>
	);
}
