const nonce = (window as any).wpApiSettings.nonce;
const isUserLoggedIn = (window as any).wpApiSettings.isUserLoggedIn
export default class API {
	public static async getListings(
		domain: string,
		q: string,
		page: number,
		page_size: number,
		order: string,
		status: string,
	) {
        const url = `${domain}/listings?q=${q}&page=${page}&page_size=${page_size}&order=${order}&status=${status}`;
        let data = await fetch(url, {
            method: 'GET',
            headers: {
				'Content-Type': 'application/json',
                'X-WP-Nonce': nonce // Set nonce as a header
            }
        });
        data = await data.json();
        return data;
    }

	public static async getCategories(domain: string) {
		let data = await fetch(`${domain}/categories`);
		data = await data.json();
		return data;
	}

	public static async getLocations(domain: string) {
		let data = await fetch(`${domain}/locations`);
		data = await data.json();
		return data;
	}

	public static async getAdditionalInformation(
		domain: string,
		category: string,
		listingId?: number,
	) {
		const url = new URL(`${domain}/getAdditionalInformation/${category}`);
		if (listingId !== undefined) {
			url.searchParams.append("listingId", listingId.toString());
		}
		let data = await fetch(url.toString());
		if (!data.ok) {
			throw new Error("Network response was not ok");
		}
		data = await data.json();
		return data;
	}

	public static async getHighlightedInformation(
		domain: string,
		category: string,
		listingId?: number,
	) {
		const url = new URL(`${domain}/getHighlightedInformation/${category}`);
		if (listingId !== undefined) {
			url.searchParams.append("listingId", listingId.toString());
		}
		let data = await fetch(url.toString());
		if (!data.ok) {
			throw new Error("Network response was not ok");
		}
		data = await data.json();
		return data;
	}

	public static async uploadImage(domain: string, imageFile: File) {
		const formData = new FormData();
		formData.append("image", imageFile);
		const response = await fetch(`${domain}/upload-image`, {
			method: "POST",
			headers: {
                'X-WP-Nonce': nonce // Set nonce as a header
            },
			body: formData,
		});
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Upload failed");
		}
		const responseBodyAsText = await response.text();
		const id = parseInt(responseBodyAsText, 10);
		return id;
	}

	public static async createListing(domain: string, listingPost: any) {
		const response = await fetch(`${domain}/listing`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-WP-Nonce': nonce
			},
			body: JSON.stringify(listingPost),
		});
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || 'Invalid input');
		}
		const responseBody = await response.json();
		return responseBody;
	}

	public static async deleteListings(domain: string, listingIds: number[]) {
		const url = new URL(`${domain}/delete-listings`);

		const response = await fetch(url.toString(), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'X-WP-Nonce': nonce // Set nonce as a header
			},
			body: JSON.stringify({ listing_ids: listingIds }),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Delete failed");
		}
	}

	public static async getListing(domain: string, id: number) {
		const url = new URL(`${domain}/listing/${id}`);

		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				'X-WP-Nonce': nonce // Set nonce as a header
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Listing retrieval failed");
		}

		return await response.json();
	}

	public static async updateListing(domain: string, id: number, listingData: object) {
		const url = new URL(`${domain}/listing/${id}`);

		const response = await fetch(url.toString(), {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				'X-WP-Nonce': nonce // Set nonce as a header
			},
			body: JSON.stringify(listingData),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Update failed");
		}

		return await response.json();
	}

	public static debounce(func: Function, delay: number) {
		let timeoutId: ReturnType<typeof setTimeout>;
		return function (...args: any[]) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				// @ts-ignore
				func.apply(this, args);
			}, delay);
		};
	}

	public static async updateProfile(domain: string, profile: object) {
		const url = new URL(`${domain}/update-user`);

		const response = await fetch(url.toString(), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'X-WP-Nonce': nonce // Set nonce as a header
			},
			body: JSON.stringify(profile),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Update failed");
		}

		return await response.json();
	}

	public static async getProfile(domain: string) {
		const url = new URL(`${domain}/update-user`);

		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				'X-WP-Nonce': nonce // Set nonce as a header
			}
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Get failed");
		}

		return await response.json();
	}

	public static async deleteProfile(domain: string) {
		const url = new URL(`${domain}/delete-user`);

		const response = await fetch(url.toString(), {
			method: "POST",
			headers: {
				'X-WP-Nonce': nonce
			}
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Delete profile failed");
		}

		return await response.json();
	}

	public static async switchUserType(domain: string) {
		const url = new URL(`${domain}/switch-user-type`);

		const response = await fetch(url.toString(), {
			method: "POST",
			headers: {
				'X-WP-Nonce': nonce
			}
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Profile type change failed");
		}

		return await response.json();
	}
}
