import React from "react";
import { useNavigate } from "react-router-dom";

export function Navigation() {
	const navigate = useNavigate();

	function setWindowScrollingState() {
		document.body.style.width = "auto";
		document.body.style.height = "auto";
		document.getElementsByTagName("html")[0].style.overflow = "auto";
	}

	return (
		<div className="navigation--wrapper">
			<div className="navigation--top">
				<a
					href=""
					className={"navigation--link main " + (window.location.href.includes("/ponude") && "active")}
					onClick={(event) => {
						event.preventDefault();
						const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						settingsMenu.classList.remove("settings-visible");
						setWindowScrollingState();
						navigate("/ponude");
					}}
				>
					<p>Moje ponude</p>
				</a>
				<div className="navigation--link__group">
					<a href="#"
					   onClick={(event) => {
						   event.preventDefault();
						   const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						   settingsMenu.classList.remove("settings-visible");
						   setWindowScrollingState();
						   navigate("/postavke");
					   }}
					   className={"navigation--link main "
					   		+ (window.location.href.includes("/postavke") && "active")}>
						<p>Postavke</p>
					</a>
					<a
						href="#"
						onClick={(event) => {
							event.preventDefault();
							const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
							settingsMenu.classList.remove("settings-visible");
							setWindowScrollingState();
							navigate("/generalne-postavke");
						}}
						className={"navigation--link " + (window.location.href.includes("/generalne-postavke") && "active")}
					>
						<p>Korisnički račun</p>
					</a>
					<a href="#"
					   onClick={(event) => {
						   event.preventDefault();
						   const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						   settingsMenu.classList.remove("settings-visible");
						   setWindowScrollingState();
						   navigate("/profilna-cover");
					   }}
					   className={"navigation--link " + (window.location.href.includes("/profilna-cover") && "active")}>
						<p>Profilna i cover fotografija</p>
					</a>
					<a href="#"
					   onClick={(event) => {
						   event.preventDefault();
						   const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						   settingsMenu.classList.remove("settings-visible");
						   setWindowScrollingState();
						   navigate("/drustvene");
					   }}
					   className={"navigation--link " + (window.location.href.includes("/drustvene") && "active")}>
						<p>Linkovi na društvene mreže</p>
					</a>
					<a href=""
					   onClick={(event) => {
						   event.preventDefault();
						   const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						   settingsMenu.classList.remove("settings-visible");
						   setWindowScrollingState();
						   navigate("/promijeni-lozinku");
					   }}
					   className={"navigation--link " + (window.location.href.includes("/promijeni-lozinku") && "active")}>
						<p>Promijeni lozinku</p>
					</a>
					<a href=""
					   onClick={(event) => {
						   event.preventDefault();
						   const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
						   settingsMenu.classList.remove("settings-visible");
						   setWindowScrollingState();
						   navigate("/promijeni-email");
					   }}
					   className={"navigation--link " + (window.location.href.includes("/promijeni-email") && "active")}>
						<p>Promijeni e-mail</p>
					</a>
				</div>
			</div>

			<a
				href="#"
				className="navigation--link main"
				onClick={(event) => {
					event.preventDefault();
					const settingsMenu = document.getElementsByClassName("navigation--wrapper")[0];
					settingsMenu.classList.remove("settings-visible");
					setWindowScrollingState();
					navigate("/nova-ponuda");
				}}
			>
				<p>Dodaj ponudu</p>
				<div className="plus"></div>
			</a>
		</div>
	);
}
