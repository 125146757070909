import React, {useEffect, useRef, useState} from "react";
import DynamicForm, {DynamicFormGroup} from "../DynamicForm";
import * as z from "zod";
import API from "../api";
import LoadingSpinner from "../../shared/loading-spinner/loading-spinner";
import {set, ZodSchema} from "zod";
import {useLocation, useNavigate} from "react-router-dom";
import {Bounce, toast} from "react-toastify";
import { ProfileManagement } from "./profile-management";


export function AccountSettings({domain, kind}: {domain: string; kind: string;}) {
	const formRef = useRef<any>(null);
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [values, setValues] = useState<any>({
	});

	const [preloadedCoverImages, setPreloadedCoverImages] = useState<any[]>([]);
	const [preloadedProfileImages, setPreloadedProfileImages] = useState<any[]>([]);

	let location = useLocation();

	const setValuesFromApi = (values: any) => {
		if (values.profile_images) {
			setPreloadedProfileImages(values.profile_images);
		} else {
			setPreloadedProfileImages([]);
		}

		if (values.cover_images) {
			setPreloadedCoverImages(values.cover_images);
		} else {
			setPreloadedCoverImages([]);
		}

		setValues({
			current_password: undefined,
			new_password: undefined,
			new_password_repeat: undefined,
			current_email: undefined,
			new_email: undefined,

			...values
		});
	};

	useEffect(() => {
		setIsLoading(true);
		API.getProfile(domain).then(profile => {
			setValuesFromApi(profile);
			setIsLoading(false);
		});
	}, [location]);

	const save = async (_: any) => {
		const form = formRef.current;
		if (!form) {
			return;
		}

		Object.keys(formRef.current.values).forEach((field) => {
			formRef.current.setFieldTouched(field.toString(), true, true);
		});

		const errors = await form.validateForm();
		const values = form.values;

		let submitData: any =  {};

		if (kind === 'general') {
			submitData = {
				public_name: values.public_name,
				mobile_number: values.mobile_number,
				whatsapp: values.whatsapp,
				viber: values.viber,
				website: values.website,
				profile_description: values.profile_description,
				address: values.address
			}
		} else if (kind === 'email') {
			submitData = {
				current_email: values.current_email,
				new_email: values.new_email
			}
		} else if (kind === 'lozinka') {
			submitData = {
				current_password: values.current_password,
				new_password: values.new_password,
				new_password_repeat: values.new_password_repeat
			}
		} else if (kind === 'drustvene') {
			submitData = {
				facebook: values.facebook,
				instagram: values.instagram,
				linkedin: values.linkedin,
				youtube: values.youtube,
				tiktok: values.tiktok
			}
		} else if (kind === 'profilna') {
			submitData = {
				cover: values.cover,
				profile: values.profile
			}
		}

		submitData.ID = values.ID;

		// If there are no errors, proceed with the form submission
		if (!Object.keys(errors).length) {
			setIsLoading(true);

			API.updateProfile(domain, submitData).then(result => {
				console.log('result', result);
				if (result.require_login) {
					toast.success('Uspješno spremljeno! Molimo ponovno se ulogirajte.', {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						transition: Bounce,
					})
					confirm("Lozinka je uspješno promijenjena! Molimo Vas da se ponovno prijavite sa novom lozinkom.");
					window.location.reload();
				} else {
					toast.success('Uspješno spremljeno!', {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						transition: Bounce,
					});
					API.getProfile(domain).then(profile => {
						setValuesFromApi(profile);
						setIsLoading(false);
					});
				}
			}, error => {
				console.log('API Error', error);
				toast.error(error.toString() , {
					position: "bottom-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					transition: Bounce,
				});
				API.getProfile(domain).then(profile => {
					setValuesFromApi(profile);
					setIsLoading(false);
				});
			});
		} else {
			// Optionally, you can log or handle form errors here
			console.log("Form errors:", errors);
		}
	}
	const stringMinMax = (min: number, max: number) =>
		z.any().transform(e => e === "" ? undefined : e)
			.refine(value => {
				if (value == null)
					return true;

				if(value && (value.length < min || value.length > max)){
					return false;
				}
				return true;
			}, {
				message: `Unos treba biti između ${min} i ${max} znakova.`
			});

	const optionalUrl = ( max: number) =>
		z.any().transform(e => e === "" ? undefined : e)
			.refine(value => {
				if (value == null)
					return true;

				try {
					new URL(value);
					const lengthCheck = value.length <= max;
					return lengthCheck;
				} catch (_) {
					return false;
				}
			}, {
				message: `Unos mora biti u formatu https://www.example.com/ i imati maksimalno ${max} znakova.`
			});



	const mobileNumberFormat = z.string().max(16, { message: "Predugo." }).optional().refine(value => {
		// If value is undefined or empty, pass the validation
		if (!value) {
			return true;
		}

		// If value exists, it must pass the regex test
		return (/^\+\d{10,15}$/).test(value);
	}, { message: "Nije u formatu +385991234567." });

	function validate(values: any): any {
		let validation : ZodSchema<any>;

		if (kind == 'general') {
			validation = z.object({
				public_name: stringMinMax(3, 100),
				mobile_number: mobileNumberFormat,
				website: optionalUrl(100),
				profile_description: stringMinMax(3, 2048),
				address: stringMinMax(3, 100),
			});
		} else if (kind == 'email') {
			validation = z.object({
				current_email: z.string({required_error: "Obavezno polje."}).email({message: "Neispravan format."}), // Email validation
				new_email: z.string({required_error: "Obavezno polje."}).email({message: "Neispravan format."}) // Email validation
			});
		} else if (kind == 'lozinka') {
			validation = z.object({
				current_password: z.string({required_error: "Obavezno polje."}).min(1, {message: 'Obavezno polje.'}),
				new_password: z.string({required_error: "Obavezno polje."}).min(8, {message: 'Lozinka mora biti najmanje 8 znakova.'}).refine(val => /^[a-zA-Z0-9]+$/.test(val), {message: 'Lozinka može samo sadržavati alfanumeričke znakove.'}),
				new_password_repeat: z.string({required_error: "Obavezno polje."}).min(8, {message: 'Lozinka mora biti najmanje 8 znakova.'}),
			});
		} else if (kind == 'drustvene') {
			validation = z.object({
				facebook:  optionalUrl(250),
				instagram: optionalUrl(100),
				linkedin:  optionalUrl(100),
				youtube:  optionalUrl(100),
				tiktok:  optionalUrl(100),
			});
		}

		try {
			validation.parse(values);
		} catch (error) {
			if (error instanceof z.ZodError) {
				const formikErrors = error.flatten().fieldErrors;
				let errors: Record<string, string> = {};
				for (let key in formikErrors) {
					const errorMessage = formikErrors[key];
					if (errorMessage) {
						errors[key] = errorMessage[0];
					}
				}
				return errors;
			}
		}

		return {};
	}

	let title: String;
	let groups: DynamicFormGroup[] = [];

	if (kind === 'general') {
		title = 'Korisnički račun';
		groups = [
			{
				title: '',
				type: 'general',
				rows: [
					{
						type: 'profile',
						title: 'Ime za javni prikaz',
						fields: [
							{
								type: 'string',
								name: 'public_name',
								label: '',
								placeholder: 'Ime za javni prikaz'
							}
						]
					},
					{
						type: 'profile',
						title: 'Mobilni broj',
						fields: [
							{
								type: 'string',
								name: 'mobile_number',
								label: '',
								placeholder: 'Unesite mobilni broj'
							},
							{
								type: 'checkbox',
								name: 'whatsapp',
								label: 'WhatsApp'
							},
							{
								type: 'checkbox',
								name: 'viber',
								label: 'Viber'
							}
						]
					}, {
						type: 'profile',
						title: 'Web adresa',
						fields: [
							{
								type: 'string',
								name: 'website',
								label: '',
								placeholder: 'Unesite web adresu'
							}
						]
					}, {
						type: 'profile',
						title: 'Opis profila',
						fields: [
							{
								type: 'textarea',
								name: 'profile_description',
								label: '',
								placeholder: 'Unesite opis profila'
							}
						]
					}, {
						type: 'profile',
						title: 'Adresa',
						fields: [
							{
								type: 'string',
								name: 'address',
								label: '',
								placeholder: 'Unesite adresu'
							}
						]
					}
				]
			}
		];
	} else if (kind === 'email') {
		title = 'Promijeni e-mail';
		groups = [
			{
				title: '',
				type: 'general',
				rows: [
					{
						type: 'profile',
						title: 'Trenutna mail adresa',
						fields: [
							{
								type: 'string',
								name: 'current_email',
								label: '',
								placeholder: 'Unesi trenutni mail'
							}
						]
					},
					{
						type: 'profile',
						title: 'Nova mail adresa',
						fields: [
							{
								type: 'string',
								name: 'new_email',
								label: '',
								placeholder: 'Unesi novi mail'
							}
						]
					},
				]
			}
		];
	} else if (kind === 'lozinka') {
		title = 'Promijeni lozinku';
		groups = [
			{
				title: '',
				type: 'general',
				rows: [
					{
						type: 'profile',
						title: 'Trenutna lozinka',
						fields: [
							{
								type: 'password',
								name: 'current_password',
								label: '',
								placeholder: 'Unesi trenutnu lozinku'
							}
						]
					},
					{
						type: 'profile',
						title: 'Nova lozinka',
						fields: [
							{
								type: 'password',
								name: 'new_password',
								label: '',
								placeholder: 'Unesi novu lozinku'
							}
						]
					},
					{
						type: 'profile',
						title: 'Ponovi lozinku',
						fields: [
							{
								type: 'password',
								name: 'new_password_repeat',
								label: '',
								placeholder: 'Ponovi novu lozinku'
							}
						]
					},
				]
			}
		];
	} else if (kind === 'drustvene') {
		title = 'Linkovi na društvene mreže';
		groups = [
			{
				title: '',
				type: 'general',
				rows: [
					{
						type: 'profile',
						title: 'Facebook',
						fields: [
							{
								type: 'string',
								name: 'facebook',
								label: '',
								placeholder: 'Unesi link'
							}
						]
					},
					{
						type: 'profile',
						title: 'Instagram',
						fields: [
							{
								type: 'string',
								name: 'instagram',
								label: '',
								placeholder: 'Unesi link'
							}
						]
					},
					{
						type: 'profile',
						title: 'LinkedIn',
						fields: [
							{
								type: 'string',
								name: 'linkedin',
								label: '',
								placeholder: 'Unesi link'
							}
						]
					},
					{
						type: 'profile',
						title: 'Youtube',
						fields: [
							{
								type: 'string',
								name: 'youtube',
								label: '',
								placeholder: 'Unesi link'
							}
						]
					},
					{
						type: 'profile',
						title: 'Tik Tok',
						fields: [
							{
								type: 'string',
								name: 'tiktok',
								label: '',
								placeholder: 'Unesi link'
							}
						]
					},
				]
			}
		];
	} else if (kind === 'profilna') {
		title = 'Profilna i cover fotografija';
		groups = [
			{
				title: '',
				type: 'general',
				rows: [
					{
						type: 'profile',
						title: 'Cover fotografija',
						fields: [
							{
								type: 'cover',
								name: 'cover',
								label: '',
								placeholder: ''
							}
						]
					},
					{
						type: 'profile',
						title: 'Profile fotografija',
						fields: [
							{
								type: 'profile',
								name: 'profile',
								label: '',
								placeholder: ''
							}
						]
					}
				]
			}
		];
	} else {
		title = kind;
		groups = [];
	}

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return <div>
		<div className="profile">
			<div className=
			{`${window.location.href.includes("generalne-postavke") ? 'profile__title__container' : ''}
			${document.querySelector("#wpadminbar") ? 'wpadmin' : ''}`}
			>
				<span className="profile__back__button">
					<a href="" onClick={ (event) => {
						event.preventDefault();
						navigate("/postavke");
					}}>
						<img src="/wp-content/themes/shopaj/src/assets/images/arrow_left.svg" alt="" />
					</a>
				</span>

				<div className="title">
						<span className="title--text">
							<h1>{title}</h1>
						</span>

					<div className="title--buttons">
						<button
							className="button publish"
							onClick={async (event) => {
								save(formRef.current);
								event.preventDefault();
							}}
						>
							Spremi izmjene
						</button>
					</div>
				</div>
			</div>

			<DynamicForm
				ref={formRef}
				groups={groups}
				selectChoices={{}}
				initialValues={values}
				validate={validate}
				initialImages={{
					'cover': preloadedCoverImages,
					'profile': preloadedProfileImages
				}}
				submit={async (values) => {

				}}
				onChange={(change) => {

				}}
				onRelatedOptionsChange={(newValue) => {
				}}
				hideButtons={true}
				domain={domain}
			/>

			{ kind === 'general' &&
				<ProfileManagement domain={domain} values={values}>
				</ProfileManagement>
			}
		</div>
	</div>;
}
