import { addQueryArgs } from "@wordpress/url";
import apiFetch from "@wordpress/api-fetch";

export function rendererPath(
	block: string,
	attributes = null,
	urlQueryArgs = {},
) {
	return addQueryArgs(`/wp/v2/block-renderer/${block}`, {
		context: "edit",
		...(null !== attributes ? { attributes } : {}),
		...urlQueryArgs,
	});
}

export async function fetchServerSideRender(block: string, attributes: any) {
	const response = await apiFetch({
		path: rendererPath(block, attributes),
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	});
	if (!response) {
		throw new Error("Failed to fetch server-side render");
	}
	const fetchedJsonContent: { rendered?: string } = await response;

	const regex = /<script type="application\/json">(.*?)<\/script>/s;
	const match = fetchedJsonContent.rendered?.match(regex);

	if (match && match[1]) {
		const jsonString = match[1];
		const jsonObject = JSON.parse(jsonString);
		return jsonObject;
	} else {
		throw new Error("JSON content not found in the input string.");
	}
}

export function getCroatianCurrencyFormat(price: string | number) {
	return new Intl.NumberFormat("hr-HR", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(Number(price));
}

let debounceTimer = false;
export function debounce(fn: Function, ms = 300) {
	if (debounceTimer) {
		return;
	}
	debounceTimer = true;
	setTimeout(() => {
		debounceTimer = false;
	}, ms);
	fn();
	return;
}
