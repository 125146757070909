import React from "react";
import { useState, useEffect, ReactNode } from "react";

interface PaginationProps {
	page: number;
	totalPages: number;
	useQueryParams?: boolean;
	queryParamsCallback?: (page: number) => void;
	useUnderscore?: boolean; // this modifies the query parameter name from paged to _paged, this bypasses the default WP pagination which redirects to a 404 page
}

export default function Pagination({
	page = 1,
	totalPages,
	useQueryParams = true,
	queryParamsCallback,
	useUnderscore = false,
}: PaginationProps) {
	const [paginationLinks, setPaginationLinks] = useState<ReactNode[]>([]);
	const [previousPageLink, setPreviousPageLink] = useState<string>();
	const [nextPageLink, setNextPageLink] = useState<string>();

	const getPaginationLink = (page: number | string) => {
		const queryParams = new URLSearchParams(window.location.search);
		if (useUnderscore) {
			queryParams.set("_paged", page.toString());
		} else {
			queryParams.set("paged", page.toString());
		}
		const newQueryString = queryParams.toString();
		const baseUrl = window.location.href.split("?")[0];
		return baseUrl + "?" + newQueryString;
	};

	useEffect(() => {
		const range = getPaginationRange(page, totalPages);

		const paginationLinks: ReactNode[] = [];
		range.map((item: number | string, index: number) => {
			const newUrl = getPaginationLink(item);

			paginationLinks.push(
				<a
					key={index}
					className={`pagination__number${
						item === page ? " pagination__number--active" : ""
					}${item === "..." ? " pagination__number--dots" : ""}`}
					href={item == page || item === "..." ? undefined : newUrl}
					onClick={(e) => {
						if (!useQueryParams) {
							e.preventDefault();
							queryParamsCallback && queryParamsCallback(item as number);
						}
					}}
				>
					{item === "..." ? "..." : item}
				</a>,
			);
		});

		setPaginationLinks(paginationLinks);

		// links for previous and next page
		if (page > 1) {
			const prevUrl = getPaginationLink(page - 1);
			setPreviousPageLink(prevUrl);
		}
		if (page < totalPages) {
			const nextUrl = getPaginationLink(page + 1);
			setNextPageLink(nextUrl);
		}
	}, [page]);

	return (
		<div className="pagination">
			<a
				className={`pagination__btn${
					page > 1 ? " pagination__btn--active" : ""
				}`}
				href={previousPageLink}
			>
				<div className="pagination__btn_icon"></div>
				<span>Prethodno</span>
			</a>
			<div className="pagination__link_container">{paginationLinks}</div>
			<a
				className={`pagination__btn${
					page < totalPages ? " pagination__btn--active" : ""
				}`}
				href={nextPageLink}
			>
				<span>Sljedeće</span>
				<div className="pagination__btn_icon"></div>
			</a>
		</div>
	);
}

function getPaginationRange(page: number, totalPages: number) {
	// number of possible buttons to show
	const maxButtonCount = 7;

	const current = page;
	const last = totalPages;
	const delta = 1;
	const left = current - delta;
	const right = current + delta + 1;
	const range = [];
	const rangeWithDots = [];
	let l;

	for (let i = 1; i <= last; i++) {
		// this condition makes sure that we always the same number of buttons (maxButtonCount)
		if (
			maxButtonCount >= last ||
			(current < maxButtonCount - 1 - delta && i < maxButtonCount - 1) ||
			(current - 1 > last - (maxButtonCount - 1 - delta) &&
				i > last - (maxButtonCount - 1 - delta))
		) {
			range.push(i);
		} else if (i === 1 || i === last || (i >= left && i < right)) {
			range.push(i);
		}
	}

	for (let i of range) {
		if (l) {
			if (i - l === 2) {
				rangeWithDots.push(l + 1);
			} else if (i - l !== 1) {
				rangeWithDots.push("...");
			}
		}
		rangeWithDots.push(i);
		l = i;
	}

	return rangeWithDots;
}
