import { __ } from "@wordpress/i18n";
import { useBlockProps } from "@wordpress/block-editor";

import React from "react";
import { useEffect, useState } from "react";
import { fetchServerSideRender } from "../helpers";
import { Account } from "./view";

export default function ({ attributes, setAttributes }: any) {
	const [jsonContent, setJsonContent] = useState<any>(null);

	const blockProps = useBlockProps();

	useEffect(() => {
		async function serverSideRender() {
			try {
				const render = await fetchServerSideRender(
					"shopaj24/account",
					attributes,
				);
				setJsonContent(render);
			} catch (error) {
				console.error("Error fetching server-side render:", error);
			}
		}
		serverSideRender();
	}, [attributes]);

	return (
		<div key="block-content" {...blockProps}>
			{jsonContent != null && (
				<Account script={JSON.stringify(jsonContent)}></Account>
			)}
		</div>
	);
}
