import * as React from "react";
import { HashRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { NewOffer } from "./offers/new-offer";
import { Offers } from "./offers/offers";
import { Navigation } from "./navigation";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { AccountSettings } from "./settings/AccountSettings";
import { Settings } from "./settings/settings";
import { ToastContainer } from "react-toastify";
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

export function Account({ script }: any) {
	const domain = script.domain;

	return (
		<div className="account">
			<HashRouter basename="">
				<Navigation />
				<ScrollToTop /> {/* Here we use the ScrollToTop component */}
				<div className="account--main">
					<Routes>
						<Route path="/postavke" element={<Settings />} />
						<Route path="/nova-ponuda" element={<NewOffer domain={domain} />} />
						<Route path="/ponude/:id" element={<NewOffer domain={domain} />} />
						<Route
							path="/generalne-postavke"
							element={<AccountSettings domain={domain} kind={"general"} />}
						/>
						<Route
							path="/profilna-cover"
							element={<AccountSettings domain={domain} kind={"profilna"} />}
						/>
						<Route
							path="/drustvene"
							element={<AccountSettings domain={domain} kind={"drustvene"} />}
						/>
						<Route
							path="/promijeni-lozinku"
							element={<AccountSettings domain={domain} kind={"lozinka"} />}
						/>
						<Route
							path="/promijeni-email"
							element={<AccountSettings domain={domain} kind={"email"} />}
						/>
						<Route path="/ponude" element={<Offers domain={domain} />} />
						<Route path="/*" element={<Navigate to="/ponude" />} />
					</Routes>
				</div>
			</HashRouter>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
		</div>
	);
}

class AccountElement extends HTMLElement {
	connectedCallback() {
		const jsonContent = JSON.parse(
			this.querySelector('script[type="application/json"]')!.innerHTML.trim(),
		);
		const root = createRoot(this);
		root.render(<Account script={jsonContent}></Account>);
	}
}

if (!customElements.get("shwp-account")) {
	customElements.define("shwp-account", AccountElement);
}
