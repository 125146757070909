import React, { FormEvent } from "react";

interface InputProps {
	name: string;
	value: string;
	onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
	allowedValues: RegExp | null;
	disableScroll?: boolean;
	type?: string;
	placeholder?: string;
}

export default function InputField({
	name,
	value,
	onChange,
	allowedValues,
	disableScroll = false,
	type = "text",
	placeholder = "",
}: InputProps) {

	function onInputEvent(event: React.KeyboardEvent<HTMLInputElement>) {
		if (allowedValues == null) return true;

		if (event.key.match(allowedValues)
			|| event.key == "Backspace"
			|| event.key == "Enter"
		) {
			return true;
		}

		event.preventDefault();
		return false;
	}

	function onPasteEvent(event: React.ClipboardEvent<HTMLInputElement>) {
		if (allowedValues == null) return true;
		let paste = event.clipboardData.getData("text");

		if (paste.match(allowedValues))
			return true;

		event.preventDefault();
		return false;
	}

	return (
		<input
			className="custom-input-field"
			type={type}
			id={name}
			name={name}
			value={value}
			placeholder={placeholder}
			onChange={(e) => onChange(e)}
			onKeyDown={(e) => onInputEvent(e) }
			onPaste={(e) => onPasteEvent(e)}
			onWheel={(e: any) => disableScroll && e.target.blur() }
		/>
	);
}
