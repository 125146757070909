"use client";

import { Formik, Form, FormikValues } from "formik";
import React, { useCallback, useEffect, useState } from "react";

import { Field, ErrorMessage, FormikErrors, FormikTouched } from "formik";

import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";

import { useDropzone } from "react-dropzone";

import {Bounce, toast} from "react-toastify";

import API from "./api";

export interface SelectChoice {
	title: string;
	value: string;
	children?: SelectChoice[];
}

export interface Image {
	id: number;
	image_name: string;
	image_size: string;
	preview_url: string;
}

function ImageUploader({
	domain,
	setValue,
	name,
	onChange,
	values,
	initialImages,
}: {
	domain: string;
	setValue: any;
	name: string;
	onChange?: (values: any) => void;
	values: FormikValues;
	initialImages?: Image[];
}) {
	const [images, setImages] = useState<
		{ image: any; imageSize: string; uploadedId: number; isInitial: boolean }[]
	>(
		initialImages
			? initialImages.map((img) => ({
					image: {
						name: img.image_name,
						preview: img.preview_url,
						isUploaded: true,
						isInitial: true,
					},
					imageSize: img.image_size,
					uploadedId: img.id,
					isInitial: true,
			  }))
			: [],
	);

	const onDrop = useCallback((acceptedFiles: any) => {
		const newImages = acceptedFiles.map((file: any) => {
			let imageSize;
			const imageSizeInKb = file.size / 1024; // size in KB
			if (imageSizeInKb < 1024) {
				// If the size is less than 1 MB, show it in KB
				imageSize = `${Math.round(imageSizeInKb)} KB`;
			} else {
				// If the size is 1 MB or more, show it in MB
				const imageSizeInMb = imageSizeInKb / 1024; // size in MB
				imageSize = `${Math.round(imageSizeInMb * 10) / 10} MB`; // Round to one decimal place
			}

			return {
				image: {
					file: file,
					name: file.name,
					preview: URL.createObjectURL(file),
					isUploaded: false,
				},
				imageSize: imageSize,
				uploadedId: 0,
			};
		});

		setImages((prevImages) => [...prevImages, ...newImages]);

		newImages.forEach((imageObject: any) =>
			uploadImage(imageObject.image.file),
		);
	}, []);

	const { getRootProps, getInputProps, open } = useDropzone({
		accept: { "image/*": [] },
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	const removeImage = (imageObject: { image: any; uploadedId: number }) => {
		const newImagesArray = images.filter(
			(imgObj) => imgObj.image.name !== imageObject.image.name,
		);
		setImages(newImagesArray);
		setValue(
			name,
			newImagesArray.map((imgObj) => imgObj.uploadedId),
		);
		onChange && onChange(newImagesArray.map((imgObj) => imgObj.uploadedId));
	};

	const uploadImage = (file: File) => {
		const supportedTypes: string[] = ['image/jpeg', 'image/png'];
    	const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

    // Validate file type
		if (!supportedTypes.includes(file.type)) {
			toast.error('Nepodržani format slike.', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});
			setImages(prevImages => prevImages.slice(0, -1));
			return Promise.reject(new Error('Nepodržani format slike.'));
		}

    // Validate file size
		if (file.size > maxFileSize) {
			toast.error('Slika je prevelika (max 2MB).', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});
			setImages(prevImages => prevImages.slice(0, -1));
			return Promise.reject(new Error('Slika je prevelika (max 2MB).'));
    }
		return API.uploadImage(domain, file)
			.then((id) => {
				setImages((prevImages) => {
					const updatedImages = prevImages.map((imgObj) =>
						imgObj.image.file === file
							? {
									...imgObj,
									image: { ...imgObj.image, isUploaded: true },
									uploadedId: id,
							  }
							: imgObj,
					);
					// Setting the value right after calculating the new state
					setValue(
						name,
						updatedImages.map((imgObj) => imgObj.uploadedId),
					);
					//onChange &&
					//	onChange(updatedImages.map((imgObj) => imgObj.uploadedId));
					return updatedImages; // Returning the new state
				});
			})
			.catch((e) => Promise.reject(e));
	};

	const [isHovering, setIsHovering] = useState(false);

	return (
		<div
			onDragEnterCapture={(e) => e.target.classList.add("dragEnter")}
			onDragLeaveCapture={(e) => e.target.classList.remove("dragEnter")}
			onDropCapture={(e) => e.target.classList.remove("dragEnter")}
			className={`${images.length && "hasContent"} ${
				images.length === 10 && "full"
			} dropfield ${isHovering ? "hover" : undefined}`}
			onDragOver={() => {
				setIsHovering(true);
			}}
			onDragLeave={() => {
				setIsHovering(false);
			}}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			<div className="description--upper">
				<img src="/wp-content/plugins/shopaj-blocks/src/assets/file.svg" />
				<p>Povuci sadržaj ili</p>
				<h6>Povucite sadržaj ovdje</h6>
			</div>
			<button type="button" className="description--lower" onClick={open}>
				<img src="/wp-content/plugins/shopaj-blocks/src/assets/clip.svg" />{" "}
				<p>Odaberi datoteku</p>
			</button>
			{images.map((imageObject) => (
				<div className="image-content" key={imageObject.image.name}>
					<button
						type="button"
						className="image-remove"
						onClick={() => removeImage(imageObject)}
					>
						<img src="/wp-content/plugins/shopaj-blocks/src/assets/close.svg" />
					</button>
					<img
						src={imageObject.image.preview}
						style={{ opacity: imageObject.image.isUploaded ? "1" : "0.1" }}
						alt={imageObject.image.name}
					/>
					<h5 style={{ opacity: imageObject.image.isUploaded ? "1" : "0.1" }}>{imageObject.image.name}</h5>
					<h6>{imageObject.imageSize}</h6>
				</div>
			))}
			<button type="button" onClick={open} className="image-add">
				<img src="/wp-content/plugins/shopaj-blocks/src/assets/plus.svg" />
				<p>Dodaj sadržaj</p>
			</button>
		</div>
	);
}

function SingleImageUploader({
							size,
						   	domain,
						   	setValue,
						   	name,
						   	onChange,
						   	values,
						   	initialImages,
					   }: {
	size: string;
	domain: string;
	setValue: any;
	name: string;
	onChange?: (values: any) => void;
	values: FormikValues;
	initialImages?: Image[];
}) {
	const [images, setImages] = useState<
		{ image: any; imageSize: string; uploadedId: number; isInitial: boolean }[]
	>(
		initialImages
			? initialImages.map((img) => ({
				image: {
					name: img.image_name,
					preview: img.preview_url,
					isUploaded: true,
					isInitial: true,
				},
				imageSize: img.image_size,
				uploadedId: img.id,
				isInitial: true,
			}))
			: [],
	);

	const onDrop = useCallback((acceptedFiles: any) => {
		const newImages = acceptedFiles.map((file: any) => {
			let imageSize;
			const imageSizeInKb = file.size / 1024; // size in KB
			if (imageSizeInKb < 1024) {
				// If the size is less than 1 MB, show it in KB
				imageSize = `${Math.round(imageSizeInKb)} KB`;
			} else {
				// If the size is 1 MB or more, show it in MB
				const imageSizeInMb = imageSizeInKb / 1024; // size in MB
				imageSize = `${Math.round(imageSizeInMb * 10) / 10} MB`; // Round to one decimal place
			}

			return {
				image: {
					file: file,
					name: file.name,
					preview: URL.createObjectURL(file),
					isUploaded: false,
				},
				imageSize: imageSize,
				uploadedId: 0,
			};
		});

		setImages((prevImages) => [...prevImages, ...newImages]);

		newImages.forEach((imageObject: any) =>
			uploadImage(imageObject.image.file),
		);
	}, []);

	const { getRootProps, getInputProps, open } = useDropzone({
		accept: { "image/*": [] },
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	const removeImage = (imageObject: { image: any; uploadedId: number }) => {
		const newImagesArray = images.filter(
			(imgObj) => imgObj.image.name !== imageObject.image.name,
		);
		setImages(newImagesArray);
		setValue(
			name,
			newImagesArray.map((imgObj) => imgObj.uploadedId),
		);
		onChange && onChange(newImagesArray.map((imgObj) => imgObj.uploadedId));
	};

	const uploadImage = (file: File) => {
		return API.uploadImage(domain, file)
			.then((id) => {
				setImages((prevImages) => {
					const updatedImages = prevImages.map((imgObj) =>
						imgObj.image.file === file
							? {
								...imgObj,
								image: { ...imgObj.image, isUploaded: true },
								uploadedId: id,
							}
							: imgObj,
					);
					// Setting the value right after calculating the new state
					setValue(
						name,
						updatedImages.map((imgObj) => imgObj.uploadedId),
					);
					//onChange &&
					//	onChange(updatedImages.map((imgObj) => imgObj.uploadedId));
					return updatedImages; // Returning the new state
				});
			})
			.catch((e) => Promise.reject(e));
	};

	const [isHovering, setIsHovering] = useState(false);

	return (
		<div className={"item--singleImageUploader--container item--singleImageUploader--container--size-" + size}>
			<div
				onDragEnterCapture={(e) => e.target.classList.add("dragEnter")}
				onDragLeaveCapture={(e) => e.target.classList.remove("dragEnter")}
				onDropCapture={(e) => e.target.classList.remove("dragEnter")}
				className={`${images.length && "hasContent"} ${
					images.length === 10 && "full"
				} dropfield ${isHovering ? "hover" : undefined}`}
				onDragOver={() => {
					setIsHovering(true);
				}}
				onDragLeave={() => {
					setIsHovering(false);
				}}
				{...getRootProps()}

				onClick={(event) => {
					event.preventDefault()
					images.forEach(img => removeImage(img))
					open()
				}}
			>
				<input {...getInputProps()} />
				<div className="description--upper">
					<p>Učitaj fotografiju</p>
				</div>
				{images.map((imageObject) => (
					<div className="image-content" key={imageObject.image.name}>
						<img
							src={imageObject.image.preview}
							style={{ opacity: imageObject.image.isUploaded ? "1" : "0.5" }}
							alt={imageObject.image.name}
						/>
					</div>
				))}
			</div>

			{
				<div className={"item--singleImageUploader--buttons"}>
					{ (images.length > 0 || size == 'profile') && <div className={"item--singleImageUploader--button"} onClick={(event) => {
						event.preventDefault()
						images.forEach(img => removeImage(img))
						open()
					}}>Promijeni
					</div>}
					{ images.length > 0 && <div className={"item--singleImageUploader--button item--singleImageUploader--button--delete"} onClick={(event) => {
						event.preventDefault()
						images.forEach(img => removeImage(img))
					}}>Ukloni
					</div>}
				</div>
			}


		</div>
	);
}

type PreloadedImagesDict = Record<string, Image[]>;

/**
 * Renders a form input field with label, name, type, placeholder, error messages,
 * and disabled state.
 *
 * @param {object} options - The options object for configuring the form input field.
 * @param {string} options.label - The label text for the form input field.
 * @param {string} options.name - The name attribute for the form input field.
 * @param {string} [options.type] - The type attribute for the form input field.
 * @param {boolean} [options.disabled] - Whether the form input field is disabled or not.
 * @param {string} [options.placeholder] - The placeholder text for the form input field.
 * @param {object} [options.touched] - An object representing the touched state of the form input field.
 * @param {object} [options.errors] - An object representing the errors for the form input field.
 *
 * @return {JSX.Element} The rendered form input field component.
 */
export function DynamicFormInput({
	label,
	name,
	type,
	icon,
	placeholder,
	touched,
	errors,
	disabled,
	onChange,
	choices,
	dependsOn,
	setFieldValue,
	values,
	domain,
	initialImages,
}: {
	label?: string;
	name: string;
	type?: string;
	icon?: string;
	disabled?: boolean;
	placeholder?: string;
	touched?: FormikTouched<any>;
	errors?: FormikErrors<any>;
	onChange?: (
		value: any,
		name: string,
		setFieldValue: (name: string, value: any) => void,
		values: FormikValues,
	) => void;
	choices?: SelectChoice[];
	dependsOn?: any;
	setFieldValue: (name: string, value: any) => void;
	values: FormikValues;
	domain: string;
	initialImages?: Image[];
}) {
	const hasError = errors && errors[name];
	const [isFocused, setIsFocused] = useState(false);

	switch (type) {
		case "string":
		case "password":
			return (
				<div className="infogroup--row">
					<div className="infogroup--item item--string">
						<label htmlFor={name}>{label}</label>
						<div className={`infogroup--item-input ${isFocused ? 'focused' : ''}`}>
							{icon && <div className={"infogroup--item-icon"}>

							</div>}
							<Field
								autoComplete="off"
								disabled={disabled}
								type={type}
								name={name}
								className={`${hasError ? "error-state" : "no-error-state"}`}
								placeholder={placeholder}
								onChange={(e: any) => {
									const selectValue = e.currentTarget.value;
									setFieldValue(name, selectValue);

									const copiedValues = values;
									copiedValues[name] = selectValue;
									onChange &&
									onChange(selectValue, name, setFieldValue, copiedValues);
								}}
								onFocus={() => setIsFocused(true)}
								onBlur={() => setIsFocused(false)}
							/>
						</div>

						{name && (
							<ErrorMessage
								name={name}
								component="div"
								className="error-message"
							/>
						)}
					</div>
				</div>
			);
		case "textarea":
			return (
				<div className="infogroup--row">
					<div
						className={`infogroup--item item--textarea ${isFocused ? 'focused' : ''} ${hasError ? 'error-state' : 'no-error-state'}`}>
						<Field
							as="textarea"
							autoComplete="off"
							disabled={disabled}
							name={name}

							placeholder={placeholder}
							onChange={(e: any) => {
								const selectValue = e.currentTarget.value;
								setFieldValue(name, selectValue);

								const copiedValues = {...values};
								copiedValues[name] = selectValue;
								onChange && onChange(selectValue, name, setFieldValue, copiedValues);
							}}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
						/>
					</div>
					{name && (
						<ErrorMessage name={name} component="div" className="error-message"/>
					)}
				</div>
			);
		case "number":
			return (
				<div className="infogroup--item item--number">
					<label htmlFor={name}>{label}</label>
					<Field
						autoComplete="off"
						disabled={disabled}
						type="number"
						name={name}
						className={`${hasError ? "error-state" : "no-error-state"}`}
						placeholder={placeholder}
						onChange={(e: any) => {
							const selectValue = e.currentTarget.value;
							setFieldValue(name, selectValue);

							const copiedValues = values;
							copiedValues[name] = selectValue;
							onChange &&
								onChange(selectValue, name, setFieldValue, copiedValues);
						}}
					/>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
				</div>
			);
		case "checkbox":
			return (
				<div className="infogroup--item item--checkbox">
					<Field
						autoComplete="off"
						disabled={disabled}
						type="checkbox"
						name={name}
						onChange={(e: any) => {
							const isChecked = e.currentTarget.checked;
							setFieldValue(name, isChecked);

							const copiedValues = values;
							copiedValues[name] = isChecked;
							onChange &&
								onChange(isChecked, name, setFieldValue, copiedValues);
						}}
					/>
					<span className="checkmark"></span>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
					<label>{label}</label>
				</div>
			);
		case "amount":
			return (
				<div className="infogroup--item item--amount">
					<label htmlFor={name}>{label}</label>
					<Field
						autoComplete="off"
						disabled={disabled}
						type="number"
						name={name}
						className={`${hasError ? "error-state" : "no-error-state"}`}
						placeholder={placeholder}
						onChange={(e: any) => {
							const selectValue = e.currentTarget.value;
							setFieldValue(name, selectValue);

							const copiedValues = values;
							copiedValues[name] = selectValue;
							onChange &&
								onChange(selectValue, name, setFieldValue, copiedValues);
						}}
					/>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
				</div>
			);
		case "select":
			return (
				<div className="infogroup--item item--select">
					<label htmlFor={name}>{label}</label>
					<Field
						as="select"
						autoComplete="off"
						disabled={disabled}
						name={name}
						className={`${hasError ? "error-state" : "no-error-state"}`}
						onChange={(e: any) => {
							const selectValue = e.currentTarget.value;
							setFieldValue(name, selectValue);

							const copiedValues = values;
							copiedValues[name] = selectValue;
							onChange &&
							onChange(selectValue, name, setFieldValue, copiedValues);
						}}
					>
						<option value="undefined" selected>
							{placeholder ?? "Odaberi"}
						</option>

						{choices &&
							choices.map((choice) => (
								<option key={choice.value} value={choice.value}>
									{choice.title}
								</option>
							))}
					</Field>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
				</div>
			);

		case "date_picker":
			return (
				<div className="infogroup--item item--date-picker">
					<label htmlFor={name}>{label}</label>
					<Field
						autoComplete="off"
						disabled={disabled}
						type="date"
						name={name}
						className={`${hasError ? "error-state" : "no-error-state"}`}
						placeholder={placeholder}
						onChange={(e: any) => {
							const selectValue = e.currentTarget.value;
							setFieldValue(name, selectValue);

							const copiedValues = values;
							copiedValues[name] = selectValue;
							onChange &&
								onChange(selectValue, name, setFieldValue, copiedValues);
						}}
					/>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
				</div>
			);

		case "wysiwyg":
			const blocksFromHtml = convertFromHTML(values[name]);
			const state = ContentState.createFromBlockArray(
				blocksFromHtml.contentBlocks,
				blocksFromHtml.entityMap,
			);

			const [editorState, setEditorState] = React.useState(() =>
				values[name]
					? EditorState.createWithContent(state)
					: EditorState.createEmpty(),
			);

			const onEditorStateChange = (editorState: EditorState) => {
				setEditorState(editorState);

				const newValue = stateToHTML(editorState.getCurrentContent());
				setFieldValue(name, newValue);

				const copiedValues = values;
				copiedValues[name] = newValue;
				onChange && onChange(newValue, name, setFieldValue, copiedValues);
			};
			

			return (
				<div className="infogroup--item item--wysiwyg">
					<label className="form-group__title" htmlFor={name}>
						{label}
					</label>
					<Editor
						editorState={editorState}
						toolbarClassName="toolbar"
						wrapperClassName="wrapper"
						editorClassName={`editor ${
							hasError ? "error-state" : "no-error-state"
						}`}
						onEditorStateChange={onEditorStateChange}
						toolbar={{
							options: ["inline"],
							inline: { options: ["bold", "italic", "underline"] },
						}}
					/>
					<Field
						disabled
						hidden
						value={stateToHTML(editorState.getCurrentContent())}
					/>
					<ErrorMessage name={name} component="div" className="error-message" />
				</div>
			);

		case "imageUploader":
			return (
				<div className="infogroup--item item--imageUploader">
					<label className="form-group__title" htmlFor={name}>
						{label}
					</label>
					<ImageUploader
						initialImages={initialImages}
						domain={domain}
						setValue={setFieldValue}
						name={name}
						onChange={(newValue) => {
							const copiedValues = values;
							copiedValues[name] = newValue;
							onChange && onChange(newValue, name, setFieldValue, copiedValues);
						}}
						values={values}
					/>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
				</div>
			);

		case "cover":
			return (
				<div className="infogroup--item item--singleImageUploader">
					<label className="form-group__title" htmlFor={name}>
						{label}
					</label>
					<SingleImageUploader
						initialImages={initialImages}
						size={"cover"}
						domain={domain}
						setValue={setFieldValue}
						name={name}
						onChange={(newValue) => {
							const copiedValues = values;
							copiedValues[name] = newValue;
							onChange && onChange(newValue, name, setFieldValue, copiedValues);
						}}
						values={values}
					/>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
				</div>
			);

		case "profile":
			return (
				<div className="infogroup--item item--singleImageUploader">
					<label className="form-group__title" htmlFor={name}>
						{label}
					</label>
					<SingleImageUploader
						initialImages={initialImages}
						size={"profile"}
						domain={domain}
						setValue={setFieldValue}
						name={name}
						onChange={(newValue) => {
							const copiedValues = values;
							copiedValues[name] = newValue;
							onChange && onChange(newValue, name, setFieldValue, copiedValues);
						}}
						values={values}
					/>
					{name && (
						<ErrorMessage
							name={name}
							component="div"
							className="error-message"
						/>
					)}
				</div>
			);

		default:
			throw new Error(`Type '${type}' not defined.`);
	}
}

/**
 * Represents a dynamic form field.
 *
 * @interface DynamicFormField
 */
export interface DynamicFormField {
	name: string;
	label?: string;
	placeholder?: string;
	type: string;
	dependsOn?: string;
	disabled?: boolean;
	className?: string;
	selectionChoices?: SelectChoice[];
}

export interface DynamicFormRow {
	type?: "highlighted" | "additional" | "profile";
	icon?: string;
	title?: string;
	fields: DynamicFormField[];
}

export interface DynamicFormGroup {
	type: "category" | "general" | "highlighted" | "additional";
	title: string;
	rows: DynamicFormRow[];
}

/**
 * Universal component for dynamic forms created programmatically.
 */
export default React.forwardRef<
	any,
	{
		groups: DynamicFormGroup[];
		initialValues: any;
		validate: (values: any) => any;
		submit?: (values: any) => Promise<void>;
		hideButtons?: boolean;
		selectChoices?: { [key: string]: Array<SelectChoice> };
		onChange?: (values: any) => void;
		domain: string;
		onRelatedOptionsChange?: (values: any) => void;
		initialImages?: PreloadedImagesDict;
	}
>(
	(
		{
			groups,
			initialValues,
			validate,
			submit,
			hideButtons,
			selectChoices = {},
			onChange,
			domain,
			onRelatedOptionsChange,
			initialImages
		},
		ref,
	) => {
		const fields = groups.reduce<DynamicFormField[]>((allFields, group) => {
			// For each group, extract the rows
			const groupFields = group.rows
				.map((row) => row.fields) // Map to fields array
				.reduce(
					(fieldsSoFar, fieldsInRow) => [...fieldsSoFar, ...fieldsInRow],
					[],
				); // Flatten the nested arrays

			// For each group, add its fields to the accumulative list
			return [...allFields, ...groupFields];
		}, []);

		// State for disabled form.
		const [disabled, setDisabled] = useState(false);

		// Inside your DynamicForm component
		const [relatedOptions, setRelatedOptions] = useState(selectChoices || {});

		const handleChange = (
			value: any,
			name: string,
			setFieldValue: (name: string, value: any) => void,
			values: FormikValues,
		) => {
			let updatedRelatedOptions = { ...relatedOptions };

			for (const field of fields) {
				if (field && field.dependsOn && field.dependsOn === name) {
					let selectedOption = selectChoices[name].find(
						(option: SelectChoice) => option.value.toString() === value,
					);

					let childrenOptions = selectedOption ? selectedOption.children : [];
					updatedRelatedOptions = {
						...updatedRelatedOptions,
						[field.name]: childrenOptions ?? [],
					};

					// Reset the dependent field value in Formik's state
					setFieldValue(field.name, null);
				}
			}

			if (name == 'county') {
				setFieldValue('city', null);
				setFieldValue('settlement', null);
				updatedRelatedOptions = {
					...updatedRelatedOptions,
					settlement: []
				}
			}

			setRelatedOptions(updatedRelatedOptions);
			onRelatedOptionsChange && onRelatedOptionsChange(updatedRelatedOptions);

			onChange && onChange(values);
		};

		useEffect(() => {
			setRelatedOptions(selectChoices);
			onRelatedOptionsChange && onRelatedOptionsChange(selectChoices);
		}, [selectChoices]);

		function onKeyDown(keyEvent: KeyboardEvent) {
			if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
				keyEvent.preventDefault();
			}
		}

		return (
			<Formik
				initialValues={initialValues}
				innerRef={ref}
				validateOnBlur={false}
				validateOnChange={false}
				validate={(values) => {
					// Run validation prop function.
					return validate(values);
				}}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					// Disable form when submitting.
					setDisabled(true);

					// If submit is available then call it.
					if (submit) {
						await submit(values);
					}

					// Clear submitting status (important for Formik).
					setSubmitting(false);

					// Enable the form once again.
					setDisabled(false);
				}}
			>
				{({
					touched,
					errors,
					isSubmitting,
					submitForm,
					setFieldValue,
					values,
				}) => (
					// Formic Form component
					<Form>
						{groups.filter((group) => group.rows.length > 0).map((group, i) => (
							<div
								key={i}
								className={`information--categories general ${
									group.title == "Dodatne informacije" && "additional"
								}`}
							>
								{/* Render group title */}
								{group.title && (
									<h6 className="form-group__title">{group.title}</h6>
								)}

								{group.rows.map((row, j) => {
									// Apply Bootstrap classes based on the row type
									let rowStyle = "row";
									const isOnProfileImage = window.location.href.includes("profilna-cover");
									let titleStyle = "";
									if (row.type === "additional" || row.type === "highlighted") {
										titleStyle = "col-6";
										rowStyle = "col-6";
									}

									return (
										<div
											key={j}
											className={"row dynamic-form-row row-type-" + row.type}
											style={{  marginBottom: isOnProfileImage ? "80px" : undefined}}
										>
											{row.title && (
												<div className={`${titleStyle} title-row`}>
													<div className={(isOnProfileImage ? "cover " : "")
														+ "title-row--inner"}>
														{row.icon && <img src={row.icon} />}

														<p>{row.title}</p>
													</div>
												</div>
											)}
											<div key={j} className={`${rowStyle} content-row`}>
												{row.fields.map((field, k) =>
													field ? (
														<div
															key={field.name}
															className={`form-group__row__field ${field.className}`}
														>
															<DynamicFormInput
																label={field.label}
																disabled={disabled}
																name={field.name}
																placeholder={field.placeholder}
																type={field.type}
																touched={touched}
																errors={errors}
																choices={
																	field.selectionChoices ||
																	relatedOptions[field.name] ||
																	[]
																}
																onChange={handleChange}
																setFieldValue={setFieldValue}
																values={values}
																domain={domain}
																initialImages={initialImages == undefined ? undefined : initialImages[field.name]}
															/>
														</div>
													) : (
														<div className="form-group__row__gap" />
													),
												)}
											</div>
										</div>
									);
								})}
							</div>
						))}

						{!hideButtons && (
							<button
								color="blue"
								disabled={isSubmitting}
								onClick={() => submitForm()}
								className="form-submit-btn"
							>
								Submit
							</button>
						)}
					</Form>
				)}
			</Formik>
		);
	},
);
