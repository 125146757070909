import React, { useEffect, useRef } from "react";
import { useState } from "react";

export interface Option {
	value: string;
	label: string;
}

interface Props {
	label: string;
	name: string;
	options: Option[];
	initialValue?: string;
	placeholder: string;
	onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
	isMultiSelect?: boolean;
	hasAutocomplete?: boolean;
	useLocation?: boolean;
	selectedLocation?: Option[] | null; // This controls the selected options, only used for the location input. In the future if we need to use this for other inputs, refactor.
}

export default function SelectInput({
	label,
	name,
	options,
	initialValue,
	onChange,
	placeholder,
	isMultiSelect = false,
	hasAutocomplete = false,
	useLocation = false,
	selectedLocation,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	placeholder = "Odaberite";
	const [selectedOptions, setSelectedOptions] = useState<Option[] | null>(null);
	const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (useLocation) {
			setSelectedOptions(selectedLocation ? [selectedLocation] : null);
		}
	}, [selectedLocation]);

	useEffect(() => {
		if (initialValue !== undefined && initialValue !== null) {
			const value = options.find((option) => option.value === initialValue);
			setSelectedOptions(value ? [value] : null);
		}
	}, []);

	const handleOptionClick = (option: Option) => {
		let newSelectedOptions: Option[] | null = null;
		if (!isMultiSelect) {
			newSelectedOptions = [option];
			setSelectedOptions(newSelectedOptions);
			// when the user selects the placeholder option, we want to clear the selection
		} else if (isMultiSelect && option.value === "") {
			setSelectedOptions([option]);
		} else {
			newSelectedOptions = selectedOptions?.find(
				(element) => element.value === option.value,
			)
				? selectedOptions.filter((element) => element.value !== option.value)
				: [
						...(selectedOptions
							? selectedOptions?.filter((element) => element.value !== "") // remove the placeholder option when any other option is selected
							: []),
						option,
				  ];
			setSelectedOptions(newSelectedOptions);
		}
		onChange({
			target: {
				name: name,
				value: newSelectedOptions
					? newSelectedOptions.map((element) => element.value).join(",")
					: "",
			},
		} as React.ChangeEvent<HTMLInputElement>);

		setIsOpen(false);
	};

	const handleAutocomplete = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setFilteredOptions(
			options.filter((option) =>
				option.label.toLowerCase().includes(value.toLowerCase()),
			),
		);
	};

	//focus on the input when the dropdown is opened
	useEffect(() => {
		if (isOpen && hasAutocomplete && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isOpen]);

	const toggleDropdown = () => {
		setIsOpen((prev) => !prev);
		setFilteredOptions(options);
	};

	return (
		<div className="select-input__wrapper">
			{ label && <div className="basic-input__label">{label}</div> }
			<div
				className={`select-input__select${
					!selectedOptions?.length ? " select-input__select--placeholder" : ""
				}${isOpen ? " select-input__select--open" : ""}`}
				onClick={toggleDropdown}
			>
				<div>
					{selectedOptions?.length
						? isMultiSelect
							? selectedOptions?.map((option, index) => (
									<div key={index}>{option?.label}</div>
							  ))
							: selectedOptions[0]?.label
						: placeholder}
				</div>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4 6L8 10L12 6"
						stroke="#E28C05"
						strokeWidth="1.5"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
			{isOpen && options && (
				<div className="select-input__dropdown-container">
					<div
						className="select-input__clickout"
						onClick={toggleDropdown}
					></div>
					<div className="select-input__dropdown">
						{hasAutocomplete && (
							<input
								ref={inputRef}
								type="text"
								className="select-input__autocomplete"
								placeholder="Pretraži"
								onChange={(e) => handleAutocomplete(e)}
							/>
						)}
						{filteredOptions &&
							filteredOptions.map((option) => (
								<div
									key={option.value}
									className="select-input__option"
									onClick={() => handleOptionClick(option)}
								>
									{isMultiSelect && (
										<label
											key={option.value}
											className="radio-input__field-container"
											htmlFor={option.value}
										>
											<input
												className="custom-input-field"
												type="checkbox"
												id={option.value}
												name={name}
												value={option.value}
												readOnly
												checked={
													selectedOptions?.find(
														(element) => element.value === option.value,
													)
														? true
														: false
												}
											/>
											<div className="radio-input__checkbox"></div>
										</label>
									)}
									{option.label}
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	);
}
