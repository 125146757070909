import React, { useEffect, useRef, useState } from "react";
import API from "../api";
import DynamicForm, {
	DynamicFormField,
	DynamicFormGroup,
	DynamicFormRow,
	SelectChoice,
} from "../DynamicForm";
import * as z from "zod";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import LoadingSpinner from "../../shared/loading-spinner/loading-spinner";
import JSONPretty from "react-json-pretty";
import {Bounce, toast} from "react-toastify";

export function NewOffer({ domain }: { domain: any }) {
	const navigate = useNavigate();
	let location = useLocation();
	let { id } = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(true);
	const [preloadImages, setPreloadImages] = useState<any[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<SelectChoice[]>([]);
	const [selectedLocation, setSelectedLocation] = useState<SelectChoice[]>([]);
	const [selectOptions, setSelectOptions] = useState<any>();
	const [groups, setGroups] = useState<DynamicFormGroup[]>([
		{
			title: "Odaberi kategoriju",
			type: "category",
			rows: [
				{
					fields: [
						{
							name: "category",
							type: "select",
							placeholder: "Kategorija",
							className: "col-6",
						},

						{
							name: "subcategory",
							type: "select",
							placeholder: "Potkategorija",
							dependsOn: "category",
							className: "col-6",
						},
					],
				},
			],
		},
		{
			title: "Općenite informacije",
			type: "general",
			rows: [
				{
					fields: [
						{
							name: "title",
							type: "string",
							placeholder: "Upiši naziv",
							className: "col-12",
						},
					],
				},
				{
					fields: [
						{
							name: "county",
							type: "select",
							placeholder: "Lokacija",
							className: "col-6",
						},
						{
							name: "city",
							type: "select",
							placeholder: "Grad",
							dependsOn: "county",
							className: "col-6",
						},
					],
				},
				{
					fields: [
						{
							name: "settlement",
							type: "select",
							placeholder: "Naselje",
							dependsOn: "city",
							className: "col-6",
						},
						{
							name: "price",
							type: "amount",
							placeholder: "Upiši iznos",
							className: "col-6",
						},
					],
				},
				{
					fields: [
						{
							name: "hasPrice",
							type: "checkbox",
							label: "Na upit",
							className: "offset-6 col-6",
						},
					],
				},
			],
		},
		{
			title: "",
			type: "highlighted",
			rows: [],
		},
		{
			title: "",
			type: "additional",
			rows: [],
		},
		{
			title: "",
			type: "general",
			rows: [
				{
					fields: [
						{
							name: "_description",
							type: "wysiwyg",
							label: "Napiši opis",
						},
					],
				},
				{
					fields: [
						{
							name: "images",
							type: "imageUploader",
							label: "Galerija",
						},
					],
				},
			],
		},
	]);

	const [isNew, setIsNew] = useState(id == undefined);

	const [values, setValues] = useState({
		category: undefined,
		subcategory: undefined,
		county: undefined,
		_description: "",
		title: "",
		price: "",
	});

	const formRef = useRef<any>(null);

	function transformValues(values: any) {
		const highlightedInfo: { name: string; value: string }[] = [];
		const additionalInfo: number[] = [];

		// loop through the properties of the object
		for (const property in values) {
			if (property.startsWith("highlighted-")) {
				highlightedInfo.push({
					name: property.replace("highlighted-", ""),
					value: values[property],
				});
			} else if (property.startsWith("additional-")) {
				additionalInfo.push(parseInt(property.replace("additional-", "")));
			}
		}

		return {
			title: values.title,
			description: values._description,
			price_upon_request: values.hasPrice,
			price: values.price,
			county: values.county,
			city: values.city,
			settlement: values.settlement,
			category: values.category,
			subcategory: values.subcategory,
			images: values.images,
			isBusinessOffer: values.checkbox,
			additionalInfo,
			highlightedInfo,
			isDraft: values.isDraft,
		};
	}

	function transformApiToForm(apiResponse: any) {
		// Initialize form values from the API response.
		const formValues: any = {
			title: apiResponse.title,
			_description: apiResponse.content ?? "",
			hasPrice: apiResponse.price_upon_request,
			price: apiResponse.price,
			county: apiResponse.county,
			city: apiResponse.city,
			settlement: apiResponse.settlement,
			category: apiResponse.listing_category
				? apiResponse.listing_category.toString()
				: undefined,
			subcategory: apiResponse.subcategory,
			images: apiResponse.images,
			checkbox: apiResponse.isBusinessOffer,
		};

		return formValues;
	}

	async function handleFormSubmit(values: any, isDraft: boolean) {
		setIsSaving(true);

		const transformedValues = transformValues(values);

		try {
			const result = isNew
				? await API.createListing(domain, transformedValues)
				: await API.updateListing(domain, Number(id), transformedValues);
			console.log("Listing created:", result);

			navigate("/ponude");
			setIsSaving(false);

			toast.success('Uspješno spremljeno!', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});
		} catch (error: any) {
			console.error(
				"Error creating listing:",
				error.message || "Unknown error",
			);
			setIsSaving(false);

			toast.error(error.toString() , {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				transition: Bounce,
			});
		}
	}

	function updateCategories(category: string) {
		fetchAdditionalInformation(category, undefined);
	}

	async function fetchAdditionalInformation(
		category: string,
		id: number | undefined,
	) {
		const additionalValues: any = [];
		const highlightedValues: any = {};

		try {
			const highlightedInformation: any = await API.getHighlightedInformation(
				domain,
				category,
				id,
			);
			// @ts-ignore
			const result = await API.getAdditionalInformation(domain, category, id);
			const additionalInformation: any[] = Array.isArray(result) ? result : [];

			const apiInputs = highlightedInformation.inputs ?? [];

			// Mapping fields
			let inputs: DynamicFormRow[] = apiInputs.map((input: any) => {
				// Mapping each input to a DynamicFormField
				const dynamicFormField = {
					name: `highlighted-${input.name}`,
					//label: input.label,
					type: input.type,
					placeholder: input.label, // or use another field
					className: "col-12", // assuming a default class, alter to your need
				} as DynamicFormField;

				// If input type is 'taxonomy', add 'selectionChoices'
				if (input.type === "taxonomy") {
					console.log(input.options);

					if (typeof input.options === "object" && input.options !== null) {
						// Get the keys of the input.options object
						const optionKeys = Object.keys(input.options);

						// Map over the keys to construct new objects
						dynamicFormField.selectionChoices = optionKeys.map(
							(key: string) => ({
								value: input.options[key].id,
								title: input.options[key].label,
							}),
						);
					} else {
						// Handle other cases if needed
						console.error("Unexpected input.options format");
					}

					dynamicFormField.type = "select";
				}

				highlightedValues[input.name] = input.value;

				return {
					title: input.label,
					type: "highlighted",
					fields: [dynamicFormField],
				};
			});

			let additionalRows: DynamicFormRow[] = [];
			for (let additionalInformationItem of additionalInformation) {
				const additionalApiInputs = additionalInformationItem.inputs ?? [];

				let additionalInputs = additionalApiInputs.map((input: any) => {
					// Mapping each input to a DynamicFormField
					const dynamicFormField = {
						name: `additional-${input.id}`,
						label: input.label,
						type: input.type,
						placeholder: input.label, // or use another field
						className: "col-6", // assuming a default class, alter to your need
					} as DynamicFormField;

					// If input type is 'taxonomy', add 'selectionChoices'
					if (input.type === "taxonomy") {
						dynamicFormField.selectionChoices = input.options.map(
							(option: any) => {
								return {
									value: option.id,
									title: option.label,
								};
							},
						);
						dynamicFormField.type = "select";
					}

					if (input.value == true) {
						additionalValues.push(input.id);
					}

					return dynamicFormField;
				});

				additionalRows.push({
					type: "additional",
					icon: additionalInformationItem.imageUrl,
					title: additionalInformationItem.label,
					fields: additionalInputs,
				});
			}

			const updatedAdditionalGroup: DynamicFormGroup = {
				type: "additional",
				title: additionalRows.length === 0 ? "" : "Dodatne informacije",
				rows: additionalRows,
			};

			const updatedGroup: DynamicFormGroup = {
				type: "highlighted",
				title: inputs.length === 0 ? "" : "Istaknute informacije",
				rows: inputs,
			};

			setGroups((prevGroups) => {
				// Deep copy to make sure we are not mutating state
				let newGroups = JSON.parse(JSON.stringify(prevGroups));

				// Find the index of the 'highlighted' group
				const highlightedGroupIndex = newGroups.findIndex(
					(group: DynamicFormGroup) => group.type === "highlighted",
				);

				// If it doesn't exist yet, simply add it to the groups
				if (highlightedGroupIndex === -1) {
					newGroups.push(updatedGroup);
				} else {
					// If it already exists, update it with new data
					newGroups[highlightedGroupIndex] = updatedGroup;
				}

				// Find the index of the 'highlighted' group
				const additionalGroupIndex = newGroups.findIndex(
					(group: DynamicFormGroup) => group.type === "additional",
				);

				// If it doesn't exist yet, simply add it to the groups
				if (additionalGroupIndex === -1) {
					newGroups.push(updatedAdditionalGroup);
				} else {
					// If it already exists, update it with new data
					newGroups[additionalGroupIndex] = updatedAdditionalGroup;
				}

				return newGroups;
			});

			return {
				highlightedValues,
				additionalValues,
			};
		} catch (e) {
			// handle the error accordingly
			console.error(e);

			return {
				highlightedValues: undefined,
				additionalValues: undefined,
			};
		}
	}

	function initNumberInputs() {
		let inputs = Array.from(document.getElementsByTagName("input"));

		for (let index = 0; index < inputs.length; index++) {
			const element = inputs[index];

			if (element.type == "number") {
				element.addEventListener("keydown", (event) => {
					if (
						event.key.match(/^[0-9.,]+$/) ||
						event.key == "Backspace" ||
						event.key == "Enter"
					) {
						return true;
					}

					event.preventDefault();
					return false;
				});

				element.addEventListener("paste", (event) => {
					let paste = event.clipboardData?.getData("text");

					if (paste?.match(/^[0-9]+$/))
						return true;

					event.preventDefault();
					return false;
				});

				element.addEventListener("wheel", (event) => {
					(event.target as any).blur();
				});
			}
		}
	}

	function validate(values: any): any {
		const validation = z.object({
			title: z
				.string()
				.min(10, { message: "Prekratko." })
				.max(255, { message: "Predugo." }),
			_description: z
				.string()
				.min(10, { message: "Prekratko." })
				.max(25500, { message: "Predugo." }),
			category: z.any().refine(value => {
				if (value === undefined)
					return false;

				return true;
			}, {message: 'Ovo polje je obavezno.'}),
			county: z.any().refine(value => {
				if (value === undefined)
					return false;

				return true;
			}, {message: 'Ovo polje je obavezno.'}),
			price: z.any(),
			hasPrice: z.any(),
			images: z.any(),
		}).superRefine((v, ctx) => {
			if (v.hasPrice !== true && (typeof v.price === "string" && v.price.length === 0 || v.price === undefined)) {
				ctx.addIssue({code: z.ZodIssueCode.custom, path: ['price'], message: 'Ovo polje je obavezno.'});
			}

			if (v.images === undefined || v.images.length === 0) {
				ctx.addIssue({code: z.ZodIssueCode.custom, path: ['images'], message: 'Ovo polje je obavezno.'});
			}
		});

		try {
			validation.parse(values);
		} catch (error) {
			if (error instanceof z.ZodError) {
				const formikErrors = error.flatten().fieldErrors;
				let errors: Record<string, string> = {};
				for (let key in formikErrors) {
					const errorMessage = formikErrors[key];
					if (errorMessage) {
						errors[key] = errorMessage[0];
					}
				}
				return errors;
			}
		}

		return {};
	}

	async function save(isDraft: boolean) {
		const form = formRef.current;
		if (!form) {
			return;
		}

		Object.keys(formRef.current.values).forEach((field) => {
			formRef.current.setFieldTouched(field.toString(), true, true);
		});

		const errors = await form.validateForm();
		const values = form.values;

		// If there are no errors, proceed with the form submission
		if (!Object.keys(errors).length) {
			await handleFormSubmit({ ...values, isDraft }, isDraft);
		} else {
			// Optionally, you can log or handle form errors here
			console.log("Form errors:", errors);

		}
	}


	useEffect(() => {

		// Don't load data until we have selectCategories and selectLocation which are dropdown options that need to be
		// loaded.
		if (!(selectedCategory.length && selectedLocation.length))
			return;

		setIsLoading(true);
		const fetchListing = async () => {
			if (!isNew) {
				try {
					const result = await API.getListing(domain, Number(id));

					const materialized = transformApiToForm(result);

					const { highlightedValues, additionalValues } =
						await fetchAdditionalInformation(materialized.category, Number(id));

					Object.keys(highlightedValues).map((key) => {
						materialized[`highlighted-${key}`] = highlightedValues[key];
					});

					additionalValues.forEach((id: number) => {
						materialized[`additional-${id}`] = additionalValues;
					});

					let clonedMaterialized = { ...materialized };
					delete clonedMaterialized["images"];

					clonedMaterialized["images"] = result.images.map((i: any) => i.id);

					setPreloadImages(result.images);
					setValues(clonedMaterialized);

					// Based on the values you need to change the dropdown options for the subcategories and cities dropdown.
					const processOption = (data: any, key: string, target: any[] | undefined): any => {
						if (target == undefined)
							return undefined;

						if (data[key]) {
							const dataId = parseInt(data[key]);
							const foundOption = target.find((option: any) => option.value == dataId || option.value == data[key]);
							return foundOption ? foundOption.children : undefined;
						}
						return undefined;
					}

					let subcategoryOptions = processOption(clonedMaterialized, "category", selectedCategory);
					let subCountyOptions = processOption(clonedMaterialized, "county", selectedLocation);
					let settlementOptions = processOption(clonedMaterialized, "city", subCountyOptions);

					console.log('settlementOptions', settlementOptions);

					setSelectOptions((prevOptions: any) => ({
						...prevOptions,
						...(subcategoryOptions ? { subcategory: subcategoryOptions } : {}),
						...(subCountyOptions ? { city: subCountyOptions} : {}),
						...(settlementOptions ? { settlement: settlementOptions} : {}),
					}));
				} catch (err) {
					console.error("Error setting offer", err);
				} finally {
					setIsLoading(false);
				}
			} else {
				setIsLoading(false);
			}
		};

		fetchListing();
	}, [isNew, domain, id, selectedCategory, selectedLocation, location]);

	useEffect(() => {
		setValues({
			category: undefined,
			subcategory: undefined,
			county: undefined,
			_description: "",
			title: "",
			price: "",
		});
		setIsNew(id === undefined);
		setIsLoading(true);
		setSelectedCategory([]);
		setSelectedLocation([]);
		setIsAutocompleteLoading(true);

		const initializeData = async () => {
			// @ts-ignore
			const categories: any[] = await API.getCategories(domain);
			// @ts-ignore
			const locations: any[] = await API.getLocations(domain);

			const createCategoryObjects = (categoryList: any[]): any[] => {
				return categoryList.map((c) => ({
					title: c.name,
					value: c.id,
					children: c.children ? createCategoryObjects(c.children) : [],
				}));
			};


			const createLocationObjects = (locationList: any[]): any[] => {
				return locationList.map((location) => ({
					title: location.label,
					value: location.id,
					children:
						location.children && location.children.length > 0
							? createLocationObjects(location.children)
							: [],
				}));
			};
			const selectLocation = createLocationObjects(locations);
			const selectCategoryObjects = createCategoryObjects(categories);

			setSelectedLocation(selectLocation);
			setSelectedCategory(selectCategoryObjects);

			setSelectOptions({
				category: selectCategoryObjects,
				county: selectLocation,
			});
			setIsAutocompleteLoading(false);
		};

		initializeData();

	}, [location]);

	if (isLoading || isAutocompleteLoading || isSaving) {
		return <LoadingSpinner />;
	}
	else {
		initNumberInputs();
	}

	return (
		<>
			<div className="new-offer">
				<div className="title" style={{ position: "sticky" }}>
					<span className="title--text">
						<h1>{isNew ? "Dodaj ponudu" : "Uredi ponudu"}</h1>
						<p>Brzo i jednostavno objavi svoj oglas.</p>
					</span>

					<div className="title--buttons">
						<button
							className="button save"
							onClick={async (event) => {
								event.preventDefault();
								await save(true);
							}}
						>
							Spremi kao skicu
						</button>
						<button
							className="button publish"
							onClick={async (event) => {
								event.preventDefault();
								await save(false);
							}}
						>
							Objavi ponudu
						</button>
					</div>
				</div>

				<DynamicForm
					ref={formRef}
					groups={groups}
					selectChoices={selectOptions}
					initialValues={values}
					validate={validate}
					initialImages={{
						images: preloadImages,
					}}
					submit={async (values) => {}}
					onChange={(change) => {
						if (change.category !== undefined) {
							updateCategories(change.category);
						}
					}}
					onRelatedOptionsChange={(newValue) => {
						setSelectOptions(newValue);
					}}
					hideButtons={true}
					domain={domain}
				/>
			</div>
		</>
	);
}
